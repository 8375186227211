<template>
  <div class="filter-ticket">
    <esmp-modal
      v-model="show"
      title="Фильтр"
      ok-text="Применить"
      class-name="filter-ticket__modal"
      @on-ok="filterTickets"
    >
      <esmp-checkbox v-model="isFired" class="filter-ticket__is-fired">
        Поиск по уволенным сотрудникам
      </esmp-checkbox>
      <esmp-select
        ref="selectClient"
        class="filter-ticket__search-user"
        clearable
        placeholder="Поиск сотрудников"
        filterable
        :loading="loading"
        :show-search-results="showSearchResults"
        :remote-method="waitClientPrint"
        @on-query-change="setQuery"
        @on-select="setClientSelectedVal"
        @keydown.enter.native="searchClient"
      >
        <esmp-select-option
          v-for="r in clientSearchList"
          :value="r.login"
          :label="r.fullName"
          :key="r.login"
        >
          <div class="filter-ticket__item">
            <div class="filter-ticket__user-contact">
              <span class="filter-ticket__user-name">{{ r.fullName }}</span>
              <span class="filter-ticket__user-email" v-if="r.email">{{ r.email }}</span>
              <span class="filter-ticket__user-fired" v-if="r.fired">уволен</span>
            </div>
            <span class="filter-ticket__user-appointment" v-if="r.appointmentName">{{ r.appointmentName }}</span>
          </div>
        </esmp-select-option>
      </esmp-select>
      <esmp-select
        ref="selectService"
        class="filter-ticket__search-service"
        clearable
        placeholder="Поиск услуги"
        filterable
        :loading="loading"
        :show-search-results="showSearchResults"
        :remote-method="waitServicePrint"
        @on-query-change="setQuery"
        @on-select="setServiceSelectedVal"
        @keydown.enter.native="searchService"
      >
        <esmp-select-option
          v-for="r in serviceSearchList"
          :value="r.name"
          :label="r.name"
          :key="r.id"
        >
          {{ r.name }}
        </esmp-select-option>
      </esmp-select>
      <div class="filter-ticket__dates">
        <esmp-datepicker-adaptive
          v-model="dateStart"
          placeholder="Дата с"
          class="filter-ticket__date"
        />
        <esmp-datepicker-adaptive
          v-model="dateEnd"
          placeholder="Дата по"
          class="filter-ticket__date"
        />
      </div>
      <esmp-button
        view="function"
        icon="close-circle"
        icon-position="left"
        @click="clearFilter"
      >
        Очистить фильтры
      </esmp-button>
    </esmp-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { SEARCH_DELAY } from '@/constants/search';

export default {
  name: 'FilterTicket',
  props: {
    isShowedFilterTicket: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFired: false,
      client: null,
      service: null,
      dateStart: null,
      dateEnd: null,
      query: '',
      searchTimeoutId: null,
      loading: false,
      clientSearchList: null,
      serviceSearchList: null,
      showSearchResults: false,
    };
  },
  watch: {
    isFired() {
      this.waitClientPrint();
    },
  },
  computed: {
    ...mapState('user', ['selectedUser']),
    show: {
      get() {
        return this.isShowedFilterTicket;
      },
      set(newValue) {
        this.$emit('hide-modal', { modalName: 'filter-ticket', newValue });
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('search', ['setTicketFilter']),
    setServiceSelectedVal(val) {
      this.service = this.serviceSearchList.find((el) => el.name === val.value);
    },
    setClientSelectedVal(val) {
      this.client = this.clientSearchList.find((el) => el.login === val.value);
    },
    setQuery(val) {
      this.query = val;
    },
    waitClientPrint() {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);

      if (!this.query || this.query.length < 3) {
        this.showSearchResults = false;
      }
      if (this.query && this.query.length >= 3) {
        this.showSearchResults = true;
        this.loading = true;
        this.searchTimeoutId = setTimeout(() => {
          this.searchClient();
        }, SEARCH_DELAY);
      } else {
        this.loading = false;
      }
    },
    waitServicePrint() {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);

      if (!this.query || this.query.length < 3) {
        this.showSearchResults = false;
      }
      if (this.query && this.query.length >= 3) {
        this.showSearchResults = true;
        this.loading = true;
        this.searchTimeoutId = setTimeout(() => {
          this.searchService();
        }, SEARCH_DELAY);
      } else {
        this.loading = false;
      }
    },
    async searchService() {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);

      if (this.query) {
        this.loading = true;
        const searchData = {
          allowedOnly: true,
          query: this.query,
        };

        if (
          this.selectedUser
          && this.selectedUser.userInfoByMasterSystems
        ) {
          Object.assign(searchData, {
            userInfoByMasterSystems:
            this.selectedUser.userInfoByMasterSystems,
          });
        }

        const { data } = await this.$API.services.smartSearch({
          method: 'post',
          url: '/services/smart-search',
          data: searchData,
          params: {},
        });
        this.serviceSearchList = data.services;
        this.loading = false;
      }
    },
    async searchClient() {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
      if (this.query) {
        this.loading = true;
        const { data } = await this.$API.user.search({
          isFired: this.isFired,
          query: this.query,
        });
        this.clientSearchList = data;
        this.loading = false;
      }
    },
    clearFilter() {
      this.query = '';
      this.isFired = false;
      this.client = null;
      this.service = null;
      this.dateStart = null;
      this.dateEnd = null;
      this.clientSearchList = null;
      this.serviceSearchList = null;
      this.$refs.selectClient.reset();
      this.$refs.selectService.reset();
    },
    async filterTickets() {
      this.setLoading({ key: 'page', value: true });
      const filter = {
        client: this.client,
        service: this.service,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      };
      this.setTicketFilter(filter);
      this.setLoading({ key: 'page', value: false });
    },
  },
};
</script>

<style lang="scss">
  .filter-ticket {

    &__is-fired {
      margin-bottom: 26px;
    }

    &__search-user {
      margin-bottom: 24px;
    }

    &__search-service {
      margin-bottom: 24px;
    }

    &__dates {
      display: flex;
      margin-bottom: 26px;

      @include for-size(phone-portrait-down) {
        flex-direction: column;
      }
    }

    &__date {
      &:first-child {
        margin-right: 24px;

        @include for-size(phone-portrait-down) {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
    }

    &__user-contact {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    &__user-name {
      margin-right: 16px;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
      transition: color $base-animation-time;
    }

    &__user-email {
      font-size: 12px;
      line-height: 16px;
      color: $color-black-op-50;
      margin-right: 16px;
    }

    &__user-appointment {
      font-size: 12px;
      line-height: 16px;
      color: $color-black-op-40;
    }

    &__user-fired {
      font-size: 12px;
      line-height: 16px;
      color: $color-red;
    }
  }
</style>
